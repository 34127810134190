
import { computed, defineComponent, ref } from '@vue/composition-api'
import { debounce } from 'lodash-es'

import { UserRole, UserRoleUpdate } from '@/api/types/role'
import { useGetRoles, useUpdateRole } from '@/api/role'
import { rightsApi } from '@/api'
import { Rights } from '@/api/types/right'
import { useAuthGetters, useNotify } from '@/store'
import { DataTableHeader } from 'vuetify'

interface RightsTableRow {
  right: string
  [key: number]: boolean
}

export default defineComponent({
  name: 'AdminRightsView',
  setup(_, { root }) {
    const { exec: getRoles, data: roles, isLoading: rolesLoading } = useGetRoles()
    const { updateRole: updateRoleCall } = useUpdateRole()
    const { getRights, data: rights, isLoading: rightsLoading } = rightsApi.useGetRights()

    const { hasRight } = useAuthGetters()
    const hasUpdateRight = computed(() => hasRight.value(Rights.RIGHT_UPDATE))

    const { addNotification } = useNotify()

    const loading = computed(() => rolesLoading.value || rightsLoading.value)
    const isDebouncing = ref(false)

    const rolesToUpdate = new Map<string, UserRoleUpdate>()

    const tableHeaders = ref<DataTableHeader[]>([
      {
        text: root.$tc('form.field.right', 1) as string,
        value: 'right',
      },
    ])

    const tableItems = ref<RightsTableRow[]>([])

    const loadAsyncData = async () => {
      const rightsPromise = getRights({
        page: 0,
        size: 9999,
      }).then((rights) => rights.sort((a, b) => a.authority.localeCompare(b.authority)))

      const rolesPromise = getRoles({
        params: { size: 9999 },
      }).then((roles) => roles.sort((a, b) => a.name.localeCompare(b.name)))

      Promise.all([rightsPromise, rolesPromise])
        .then(([rights, roles]) => {
          // add all roles to the table headers
          tableHeaders.value.push(
            ...roles.map((role) => ({
              text: root.$t(`roles.names.${role.name}`) as string,
              value: role.name,
              protected: role.protected,
            }))
          )

          // transform rights and roles into table items
          tableItems.value = rights.map((right) => ({
            right: right.authority,
            description: right.description,
            ...roles
              .map((role) => ({
                [role.name]: role.rights.some((roleRight) => roleRight.authority === right.authority),
              }))
              .reduce((obj, item) => ({ ...obj, ...item })),
          }))
        })
        .catch((error) => {
          error.userMessage = root.$t('roles.get.error')

          throw error
        })
    }

    loadAsyncData()

    const onRightChange = (roleName: string, rightAuthority: string, value: boolean) => {
      const role = roles.value.find((role) => role.name === roleName)

      const right = rights.value.find((right) => right.authority === rightAuthority)
      if (role && right) {
        const rightIndex = role.rights.findIndex((roleRight) => roleRight.authority === rightAuthority)

        if (value) {
          role.rights.push(right)
        } else {
          role.rights.splice(rightIndex, 1)
        }

        rolesToUpdate.set(role.name, {
          name: role.name,
          description: role.description,
          protected: role.protected,
          rights: role.rights.map((r) => {
            return { authority: r.authority }
          }),
        })

        isDebouncing.value = true
        updateRoles()
      }
    }

    const updateRoles = debounce(
      () => {
        const promises: Promise<UserRole>[] = []
        for (const value of rolesToUpdate.values()) {
          promises.push(updateRole(value))
        }
        Promise.all(promises).then(() => {
          rolesToUpdate.clear()
        })
      },
      1000,
      { leading: true }
    )

    const updateRole = (role: UserRoleUpdate) => {
      return updateRoleCall(role)
        .then((editedRole) => {
          addNotification({
            type: 'success',
            text: root.$t('roles.update.success', {
              name: role.name,
            }) as string,
          })

          return editedRole
        })
        .catch((error) => {
          error.userMessage = root.$t('roles.update.error', {
            name: role.name,
          })

          throw error
        })
        .finally(() => {
          isDebouncing.value = false
        })
    }

    return {
      loading,
      isDebouncing,
      hasUpdateRight,
      onRightChange,
      tableHeaders,
      tableItems,
    }
  },
})
