var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "mt-16"
  }, [_c('v-card', {
    staticClass: "rounded-lg elevation-0"
  }, [_c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-data-table', {
    attrs: {
      "height": "65vh",
      "fixed-header": "",
      "dense": "",
      "headers": _vm.tableHeaders,
      "items": _vm.tableItems,
      "hide-default-footer": "",
      "disable-pagination": "",
      "loading": _vm.loading || _vm.isDebouncing
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('tr', [_c('td', [_c('v-tooltip', {
          attrs: {
            "top": "",
            "disabled": !item.description
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(" " + _vm._s(item.right) + " ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(item.description) + " ")])])], 1), _vm._l(_vm.tableHeaders.filter(function (header) {
          return header.value !== 'right';
        }), function (role) {
          return _c('td', {
            key: role.value
          }, [_c('v-simple-checkbox', {
            attrs: {
              "disabled": !_vm.hasUpdateRight || role.protected,
              "color": "primary"
            },
            on: {
              "input": function input($event) {
                return _vm.onRightChange(role.value, item.right, $event);
              }
            },
            model: {
              value: item[role.value],
              callback: function callback($$v) {
                _vm.$set(item, role.value, $$v);
              },
              expression: "item[role.value]"
            }
          })], 1);
        })], 2)];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }